<template>
	<div>
		<div class="dark-mode">
			<NavOk />
			<div class="container mt-5 mb-5">
				<div class="row justify-content-center">
					<!-- En-tête couvrant les deux colonnes -->
					<div class="col-md-12">
						<div class="card shadow-sm">
							<div
								class="card-header bg-gradient text-white"
							>
								<h3 class="mb-0 p-3">
									Conditions Générales d'Utilisation
									et RGPD
								</h3>
							</div>
						</div>
					</div>

					<!-- Contenu en deux colonnes -->
					<div class="col-md-6">
						<div class="card-body m-4">
							<section>
								<h4 class="text-primary">
									Introduction
								</h4>
								<p>
									La société eliazoura.com (« nous »)
									nous soucions de la vie privée des
									utilisateurs (l’« utilisateur » ou
									« vous ») de notre site Internet
									et/ou de notre espace mobile (le «
									Site » et l’« Espace mobile »,
									respectivement) et nous nous
									engageons à protéger les
									informations que les utilisateurs
									partagent avec nous lorsqu’ils
									utilisent notre Site et/ou notre
									Espace mobile (collectivement, les
									« Propriétés numériques »), et nous
									nous engageons pleinement à
									protéger et à utiliser vos
									informations conformément à la loi
									applicable.
									<br />
									<br />
									Cette Politique de confidentialité
									décrit nos pratiques en matière de
									recueil, d’utilisation et de
									divulgation de vos informations par
									le biais de nos propriétés
									numériques (les « Services »),
									lorsque vous accédez aux Services
									depuis votre appareil.
									<br />
									<br />

									Avant d’accéder ou d’utiliser l’un
									de nos Services, veuillez lire
									attentivement cette Politique de
									confidentialité et vous assurer que
									vous comprenez parfaitement nos
									pratiques concernant vos
									informations.
									<br />
									<br />
									Si vous lisez et comprenez
									pleinement cette Politique de
									confidentialité, et que vous restez
									opposé(e) à nos pratiques, vous
									devez immédiatement cesser toute
									utilisation de nos Propriétés
									numériques et de nos Services. En
									utilisant nos Services, vous
									acceptez les conditions de cette
									Politique de confidentialité et le
									fait de continuer à utiliser les
									Services constitue votre
									acceptation de cette Politique de
									confidentialité et de tout
									amendement à celle-ci.
								</p>
							</section>

							<section>
								<h4 class="text-primary">
									1. Les informations que nous
									recueillons
								</h4>
								<p>
									Vous trouverez ci-dessous les
									différents types d’informations que
									nous pouvons recueillir.
								</p>
								<ul>
									<li>
										<strong
											>Informations non
											identifiées et non
											identifiables</strong
										>
										: qui peuvent être fournies
										par vous lors du processus
										d’enregistrement ou
										recueillies lorsque vous
										utilisez nos Services (les «
										Informations non personnelles
										»). Les informations non
										personnelles ne nous
										permettent pas d’identifier la
										personne auprès de qui elles
										ont été recueillies.
									</li>
									<li>
										<strong
											>Informations
											personnelles</strong
										>
										: Les Informations
										personnelles que nous
										recueillons par le biais de
										nos Services sont notamment le
										nom, l’adresse e-mail,
										l’adresse, le numéro de
										téléphone, l’adresse IP ou
										d’autres informations
										demandées à chaque fois que
										nécéssaire.
									</li>
								</ul>
							</section>

							<section>
								<h4 class="text-primary">
									2. Comment nous recueillons les
									informations
								</h4>
								<p>
									Vous trouverez ci-dessous les
									principales méthodes que nous
									utilisons pour recueillir des
									informations :
								</p>
								<ul>
									<li>
										Nous recueillons des
										informations lorsque vous
										utilisez les Services.
									</li>
									<li>
										Nous recueillons les
										informations que vous nous
										fournissez volontairement.
									</li>
									<!-- <li>
									Nous pouvons recueillir des
									informations auprès de sources
									tierces.
								</li> -->
									<li>
										Nous recueillons les
										informations que vous nous
										fournissez si vous vous
										connectez à nos Services par
										le biais de services tiers
										comme Facebook ou Google.
									</li>
								</ul>
							</section>

							<section>
								<h4 class="text-primary">
									3. Pourquoi nous recueillons les
									informations ?
								</h4>
								<p>
									Nous utilisons vos Informations
									personnelles aux fins suivantes :
								</p>
								<ul>
									<li>
										Pour fournir et exploiter les
										Services.
									</li>
									<li>
										Pour développer, personnaliser
										et améliorer nos Services.
									</li>
									<li>
										Pour répondre à vos réactions,
										demandes et requêtes et vous
										apporter notre assistance.
									</li>
									<li>
										Pour analyser les schémas de
										demande et d’utilisation.
									</li>
									<!-- <li>
									À d'autres fins internes, de
									recherche ou de statistiques.
								</li> -->
									<li>
										Pour renforcer nos capacités
										en matière de sécurité des
										données et de prévention de la
										fraude.
									</li>
									<li>
										Pour enquêter sur les
										éventuelles violations, faire
										respecter nos conditions ou
										nos politiques, ou se
										conformer à une loi, une
										réglementation ou une autorité
										gouvernementale applicable.
									</li>
									<li>
										Pour vous envoyer des mises à
										jour, des avis, des documents
										promotionnels et d’autres
										informations concernant nos
										Services.
									</li>
								</ul>
							</section>

							<section>
								<h4 class="text-primary">
									4. Vos informations sont-elles
									partagées ou vendues ?
								</h4>
								<!-- <p>
								Nous pouvons partager vos informations
								avec nos fournisseurs de services, afin
								d’exploiter nos Services.
							</p> -->
								<p>
									Non, aucune information concenrant
									nos utilisateurs de façon globale
									(statistiques) ou indivuelle n'est
									vendues ou partgées. Vos
									informations restent
									confidentielles et son stockées sur
									des serveurs MongoDB avec un accès
									unique par l'administrateur de ce
									site.
									<br />
									<br />
									Des exceptions seront applqiuées
									dans les cas suivants :
									<br />
								</p>
								<ul>
									<li>
										À la demande des autorités
										compétentes afin d' enquêter,
										détecter, prévenir ou prendre
										des mesures concernant des
										activités illégales ou
										d’autres actes répréhensibles.
									</li>
									<li>
										Pour établir ou exercer nos
										droits de défense contre des
										revendications légales.
									</li>
									<li>
										Pour protéger nos droits, nos
										biens ou notre sécurité
										personnelle et ceux de nos
										utilisateurs ou du grand
										public.
									</li>
									<li>
										En cas de changement de
										contrôle de notre entreprise
										(tous les utilisateurs seront
										prévenus par e-mail au
										préalable)
									</li>
								</ul>
							</section>
						</div>
					</div>

					<div class="col-md-6">
						<div class="card-body m-4">
							<section>
								<h4 class="text-primary">
									5. Où sont stockées les
									informations ?
								</h4>
								<h5 class="text-secondary">
									Informations non personnelles
								</h5>
								<p>
									Veuillez noter que nos entreprises,
									ainsi que les partenaires et les
									fournisseurs de services à qui nous
									faisons confiance, sont situés dans
									le monde entier. Toutes les
									Informations non personnelles que
									nous recueillons sont stockées et
									traitées dans diverses juridictions
									à travers le monde.
								</p>

								<h5 class="text-secondary">
									Informations personnelles
								</h5>
								<p>
									Les Informations personnelles
									peuvent être conservées, traitées
									et stockées aux États-Unis
									d’Amérique, en Irlande, au Japon,
									en Israël et dans d’autres
									juridictions.
								</p>
							</section>

							<section>
								<h4 class="text-primary">
									6. Combien de temps sont conservées
									les informations ?
								</h4>
								<p>
									Nous conservons les informations
									que nous recueillons aussi
									longtemps que nécessaire pour
									fournir les Services et pour nous
									conformer à nos obligations légales
									ou contractuelles envers vous,
									résoudre les litiges et faire
									respecter nos accords.
								</p>
							</section>

							<section>
								<h4 class="text-primary">
									7. Protection des informations
								</h4>
								<p>
									Le service d’hébergement de nos
									Propriétés numériques met à notre
									disposition la plateforme en ligne
									qui nous permet de vous fournir le
									Service. Vos données peuvent être
									stockées par l’intermédiaire des
									applications de stockage de
									données, de bases de données ou
									générales de notre fournisseur
									d’hébergement.
								</p>
								<!-- <h5 class="text-secondary">
								A propos des paiements en ligne
							</h5> -->
								<!-- <p>
								Toutes les passerelles de paiement
								direct proposées par l’hébergeur de nos
								Propriétés numériques respectent les
								normes établies par PCI-DSS, telles que
								gérées par le PCI Security Standards
								Council.
							</p>
							<p>
								Quels que soient les efforts faits, nous
								ne pouvons pas garantir et nous ne
								garantissons pas la protection et la
								sécurité absolues des informations que
								vous téléchargez, publiez ou partagez.
							</p> -->
							</section>

							<section>
								<h4 class="text-primary">
									8. A propos des mineurs
								</h4>
								<p>
									Les Services ne sont pas destinés
									aux utilisateurs mineurs. Nous ne
									recueillons pas délibérément des
									informations auprès des enfants.
								</p>
							</section>

							<section>
								<h4 class="text-primary">
									9. Utilisation de vos informations
									personnelles
								</h4>
								<p>
									Nous n’utiliserons vos informations
									personnelles qu’aux fins définies
									dans cette Politique de
									confidentialité, si :
								</p>
								<ul>
									<li>
										L’utilisation de vos
										Informations personnelles est
										nécessaire pour exécuter un
										contrat.
									</li>
									<li>
										Il nous est nécessaire
										d’utiliser vos Informations
										personnelles pour nous
										conformer à une obligation
										légale.
									</li>
									<li>
										Il nous est nécessaire
										d’utiliser vos Informations
										personnelles pour nos intérêts
										légitimes.
									</li>
								</ul>
								<p>
									Si vous résidez dans l’UE, vous
									pouvez :
								</p>
								<ul>
									<li>
										Demander à recevoir la
										confirmation que des
										Informations personnelles vous
										concernant sont traitées ou
										non.
									</li>
									<li>
										Demander la rectification de
										vos Informations personnelles.
									</li>
									<li>
										Demander l’effacement de vos
										Informations personnelles.
									</li>
									<li>
										Vous opposez au traitement des
										données personnelles par nos
										soins.
									</li>
									<li>
										Demander la limitation du
										traitement de vos Informations
										personnelles par nos soins.
									</li>
									<li>
										Déposer une plainte auprès
										d’une autorité de contrôle.
									</li>
								</ul>
							</section>

							<section>
								<h4 class="text-primary">
									10. Droits CCPA relatifs à vos
									informations : utilisateurs
									californiens
								</h4>
								<p>
									Si vous êtes un résident de
									Californie utilisant les Services,
									la loi californienne sur la
									protection de la vie privée des
									consommateurs (la « loi CCPA »)
									peut vous donner le droit de
									demander l’accès à vos informations
									et leur suppression.
								</p>
							</section>

							<section>
								<h4 class="text-primary">
									Modifications ou mises à jour de la
									Politique de confidentialité
								</h4>
								<p>
									Nous pouvons réviser cette
									politique de confidentialité à
									chaque fois que nécessaire, et la
									version la plus récente sera
									toujours affichée sur notre site
									web.
								</p>
								<p>
									Nous vous encourageons à consulter
									régulièrement la présente Politique
									de confidentialité pour prendre
									connaissance de toute modification.
								</p>
							</section>

							<section>
								<h4 class="text-primary">
									Nous contacter
								</h4>
								<p>
									Si vous avez des questions d’ordre
									général sur les Services ou les
									informations que nous recueillons à
									votre sujet, contactez-nous sur le
									mail suivant : contact@eliazoura.fr
								</p>
							</section>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Boutons -->
		<div style="background-color: black" class="col-md-12 p-5">
			<div class="card-footer d-flex justify-content-center gap-3">
				<button class="btn btn-primary" @click="acceptTerms">
					J'accepte
				</button>
				<button class="btn btn-secondary" @click="declineTerms">
					Je refuse
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import NavOk from '../components/NavOk.vue';
export default {
	name: 'RgpdPage',
	emits: ['updatePageTitle'], // Déclarez l'événement ici

	components: {
		NavOk,
	},
	data() {
		return {
			showTerms: true,
		};
	},
	mounted() {
		// Vérifiez si les conditions ont déjà été acceptées
		const acceptedTerms = localStorage.getItem('acceptedTerms');
		if (acceptedTerms) {
			this.showTerms = false;
		}

		this.$emit(
			'updatePageTitle',
			`Conditions d'utilisation et RGPD | Eli | Développeur Full Stack`
		);
	},

	methods: {
		acceptTerms() {
			alert(
				"Vous avez accepté les conditions générales d'utilisation."
			);
			// Ajoutez ici la logique pour ce qui se passe lorsqu'un utilisateur accepte les conditions.
		},
		declineTerms() {
			alert(
				"Vous avez refusé les conditions générales d'utilisation."
			);
			// Ajoutez ici la logique pour ce qui se passe lorsqu'un utilisateur refuse les conditions.
		},
	},
};
</script>

<style scoped>
h4 + p {
	padding-left: 1rem;
}

.dark-mode {
	background-color: #121212;
	color: #ffffff;
}

.dark-mode .card {
	background-color: #1e1e1e;
}

.dark-mode .card-header {
	background-color: #333333;
}

.dark-mode h4.text-primary {
	color: #bb86fc;
}

.dark-mode h5.text-secondary {
	color: #03dac6;
}

.dark-mode .btn-primary {
	background-color: #bb86fc;
	border-color: #bb86fc;
}

.dark-mode .btn-secondary {
	background-color: #03dac6;
	border-color: #03dac6;
}

/* Utilisation du mode sombre si le navigateur est en mode sombre */
@media (prefers-color-scheme: dark) {
	.dark-mode {
		background-color: #121212;
		color: #ffffff;
	}

	.dark-mode .card {
		background-color: #1e1e1e;
	}

	.dark-mode .card-header {
		background-color: #333333;
	}

	.dark-mode h4.text-primary {
		color: #bb86fc;
	}

	.dark-mode h5.text-secondary {
		color: #03dac6;
	}

	.dark-mode .btn-primary {
		background-color: #bb86fc;
		border-color: #bb86fc;
	}

	.dark-mode .btn-secondary {
		background-color: #03dac6;
		border-color: #03dac6;
	}
}

/* Styles supplémentaires si nécessaire */
</style>
